import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { HeaderComponent } from '../../components/header/header.component';
import { Guest } from '../../core/types/guest.type';
import { CustomError } from '../../core/types/error.type';
import { TranslateModule } from '@ngx-translate/core';
import { sanitizeString } from '../../utils';
import { ReservationService } from '../../services/reservation.service';
import { GetRoomReservationResponse } from '../../core/types/room-reservation-response.type';
import { StorageService } from '../../services/storage.service';
import { Subscription } from 'rxjs';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.css'],
  standalone: true,
  imports: [RouterModule, CommonModule, HeaderComponent, ReactiveFormsModule, TranslateModule],
})
export class SearchPageComponent implements OnInit, OnDestroy {
  //propertyCode = TenantId + propertyId
  @Input() propertyCode!: string;
  @Input() langCode: string = 'It_IT';
  verificationCode!: string;
  form!: FormGroup;
  hasResults = false;
  hasBeenSearched = false;
  guest!: Guest;
  error!: CustomError;
  langFlagCodes = ['it', 'fr', 'en', 'de'];
  reservation!: GetRoomReservationResponse;
  firstName!: string;
  lastName!: string;
  isLoading!: boolean;
  isRequestInerrorList!: boolean;
  requestsFromStorage!: string | null;
  subscription: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private reservationService: ReservationService,
    private cd: ChangeDetectorRef,
    private storageService: StorageService,
    private configurationService: ConfigurationService
  ) {}

  ngOnInit() {
    this.subscribeToRouteChange();
    this.requestsFromStorage = this.storageService.readFromSessionStorage('requests');
    this.initForm();
  }

  private subscribeToRouteChange() {
    this.subscription.add(
      this.route.params.subscribe((params) => {
        const { propertyCode, langCode } = params;
        if (propertyCode && langCode) {
          this.propertyCode = propertyCode;
          this.configurationService.setPropertyCode(propertyCode);
          this.langCode = langCode;
          this.configurationService.setLangCode(langCode);
        }
      })
    );
    this.subscription.add(
      this.route.url.subscribe((url) => {
        this.configurationService.currentPageSubject.next(url[0]?.path);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getReservationFromService(verificationCode: string, firstName: string, lastName: string) {
    this.reservationService.getRoomReservation(this.propertyCode, verificationCode, firstName, lastName).subscribe({
      next: (reservation) => {
        if (reservation) {
          this.setReservation(reservation);
          this.navigateToResults(verificationCode, firstName, lastName);
        }
      },
      error: (error) => {
        this.handleReservationError();
        this.cd.detectChanges();
        console.error('Error:', error);
      },
      complete: () => {
        if (!this.reservation) {
          this.handleReservationError();
          this.cd.detectChanges();
        }
      },
    });
  }

  private handleReservationError() {
    this.manageRequestInErrorList(this.verificationCode, this.firstName, this.lastName);

    this.hasResults = false;
    this.error = {
      title: 'privacy_form.no_reservation_found_error_title',
      message: 'privacy_form.no_reservation_found_error_message',
    };
    this.isLoading = false;
    this.isRequestInerrorList = true;
  }

  private setReservation(reservation: GetRoomReservationResponse) {
    this.reservation = reservation;
    this.reservationService.setReservation(reservation);
    this.hasResults = true;

    this.storageService.writeToSessionStorage('reservation', JSON.stringify(reservation));
    this.isRequestInerrorList = false;
  }

  initForm() {
    this.form = this.fb.group(
      {
        verificationCode: [
          '',
          [
            Validators.required,
            Validators.maxLength(7),
            Validators.minLength(7),
            Validators.pattern('^[a-zA-Z0-9]+[-.,][a-zA-Z0-9]+$'),
          ],
        ],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
      },
      { validators: [this.checkValuesInStringValidator()] }
    );
    this.form.valueChanges.subscribe(() => {
      this.isRequestInerrorList = !this.form.valid;
    });
  }

  checkValuesInStringValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const targetString = this.requestsFromStorage || '';
      const firstName = control.get('firstName')?.value;
      const lastName = control.get('lastName')?.value;
      const verificationCode = control.get('verificationCode')?.value;
      const requestConcatStr = `{"verificationCode":"${verificationCode}","firstName":"${firstName}","lastName":"${lastName}"}`;
      return control.touched && control.dirty && targetString.includes(requestConcatStr)
        ? { requestisInError: true }
        : null;
    };
  }

  onSubmit(): void {
    if (this.form.valid) {
      const { verificationCode, firstName, lastName } = this.form.value;
      this.firstName = firstName;
      this.lastName = lastName;
      this.verificationCode = verificationCode;
      this.getReservationFromService(verificationCode, firstName, lastName);
    }
  }

  manageRequestInErrorList(verificationCode: string, firstName: string, lastName: string) {
    if (this.requestsFromStorage) {
      if (
        this.requestsFromStorage.includes(verificationCode) &&
        this.requestsFromStorage.includes(firstName) &&
        this.requestsFromStorage.includes(lastName)
      ) {
        // request already in error list
        return;
      } else {
        this.requestsFromStorage = JSON.stringify([
          ...JSON.parse(this.requestsFromStorage),
          { verificationCode, firstName, lastName },
        ]);
        this.storageService.writeToSessionStorage('requests', this.requestsFromStorage);
      }
    } else {
      this.storageService.writeToSessionStorage(
        'requests',
        JSON.stringify([{ verificationCode, firstName, lastName }])
      );
    }
  }

  private navigateToResults(verificationCode: string, firstName: string, lastName: string) {
    this.router.navigate([
      'results/' +
        sanitizeString(this.propertyCode) +
        '/' +
        sanitizeString(verificationCode.toLowerCase()) +
        '/' +
        sanitizeString(firstName.toLowerCase()) +
        '/' +
        sanitizeString(lastName.toLowerCase()) +
        '/' +
        this.langCode,
    ]);
  }

  fixVerificationCode() {
    if (
      this.form.get('verificationCode')?.value.length > 3 &&
      this.form.get('verificationCode')?.value.length < 7 &&
      !this.form.get('verificationCode')?.value.includes('-')
    )
      this.form
        .get('verificationCode')
        ?.setValue(
          this.form.get('verificationCode')?.value.slice(0, 3) + '-' + this.form.get('verificationCode')?.value.slice(3)
        );
  }
}
